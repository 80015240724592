<template>
  <div class="main_verification">
    <div class="title">
      <slot name="header">
        <p class="fw-800 f-16">
          {{ $t('security.securityAuthentication') }}
        </p>
      </slot>
    </div>
    <div class="body">
      <div class="word_break">
        <el-form
          label-position="top"
          :model="verificationForm"
          :rules="rulesVerification"
          ref="verificationForm"
          @keydown.enter.native.prevent
        >
          <VerificationCode
            ref="verificationCode"
            :namespace="getNamespace(action)"
            :showComponent="showComponent"
            :verifyError="verifyError"
          />
        </el-form>
      </div>
    </div>

    <div class="footer">
      <div class="footer_body">
        <el-button v-if="!isLogin" class="btn-default" @click="close" data-testid="changePw">
          {{ $t('common.button.cancel') }}
        </el-button>
        <el-button class="btn-blue" @click="submitForm('verificationForm')" data-testid="changePw">
          {{ isLogin ? $t('security.next') : $t('common.button.submit') }}
        </el-button>
      </div>
      <div
        class="contact_method word_break"
        v-html="$t('security.contactSupport', { contact_email: contactEmail })"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapVerificationOperation, mapVariable } from '@/components/security/Security.js';
import VerificationCode from '@/components/security/VerificationCode.vue';
import { apiPreValidateTotp, apiPreValidateEmail } from '@/resource';
export default {
  name: 'VerificationComponent',
  props: ['usedAuthTypes', 'action'],

  components: { VerificationCode },
  data() {
    return {
      rulesVerification: {
        verification: [
          {
            required: true,
            message: this.$t('security.verificationRequired'),
            trigger: 'blur'
          }
        ]
      },
      verificationForm: {
        verification: ''
      },
      showComponent: null,
      verifyError: null
    };
  },
  computed: {
    contactEmail() {
      return this.GLOBAL_CONTACT_EMAIL;
    },
    isLogin() {
      return this.action == 'login';
    }
  },
  mounted() {
    this.verifyError = null;
    if (this.usedAuthTypes && this.usedAuthTypes.length > 0) {
      this.validateAuthentication();
    }
  },
  watch: {
    usedAuthTypes() {
      this.validateAuthentication();
    }
  },
  methods: {
    close() {
      this.clear();
      this.$emit('changeVisible', false);
    },
    clear() {
      this.$refs.verificationCode.$refs.form.resetFields();
      this.$refs.verificationCode.stopTimer();
      this.$refs.verificationForm.resetFields();
      this.verifyError = null;
    },
    confirm() {
      this.$refs.verificationCode.stopTimer();
      this.$emit('confirm', this.action);
    },
    submitForm() {
      const [verificationForm, getVerifyCodeComponent] = [this.$refs.verificationForm, this.$refs.verificationCode];
      const [verifyVerificationMethod, verifyCodeMethod] = [
        verificationForm.validate(),
        getVerifyCodeComponent.submitForm()
      ];

      Promise.all([verifyVerificationMethod, verifyCodeMethod]).then(async valid => {
        if (valid) {
          const getVerifyCode = getVerifyCodeComponent.getFormDataOfChild();

          let tempAuth = this.$store.state.common.authHeaders;
          if (this.usedObject.type == mapVariable['email']) {
            tempAuth['etxId'] = getVerifyCode?.txId;
            tempAuth['eCode'] = getVerifyCode?.code;

            await apiPreValidateEmail({
              code: getVerifyCode?.code,
              txId: getVerifyCode?.txId
            }).then(resp => {
              if (resp.data.code != 0) {
                this.verifyError = this.$t('responseMsg.647');
              } else {
                this.verifyError = null;
              }
            });
          } else if (this.usedObject.type == mapVariable['totp']) {
            tempAuth['tCode'] = getVerifyCode?.code;
            //require to call API to pre-store the values (to avoid 30 seconds error issue)
            await apiPreValidateTotp({
              code: getVerifyCode?.code,
              flag: 'CURRENT'
            }).then(resp => {
              if (resp.data.code != 0) {
                this.verifyError = this.$t('responseMsg.647');
              } else {
                this.verifyError = null;
              }
            });
          }

          if (this.verifyError == null) {
            this.$store.commit('common/setAuthHeaders', tempAuth);
            this.$emit('updateState', this.usedObject);

            this.usedAuthTypes.map(item => (item == this.usedObject ? (item.authenticated = true) : null));
            this.validateAuthentication();

            this.$refs.verificationCode.$refs.form.resetFields();
          }
        }
      });
    },
    validateAuthentication() {
      this.verificationForm.verification = null;

      this.usedObject = this.usedAuthTypes.find(item => !item.authenticated);

      if (!this.usedObject) {
        this.confirm();
      }
      this.showComponent = mapVerificationOperation(this.usedObject?.type, this.$store.state.common.emailMasked);
    },
    getNamespace() {
      switch (this.action) {
        case 'verify-email':
          return 'CP_VERIFY_EMAIL';
        case 'modify-password':
          return 'CP_UPDATE_PASSWORD';
        case 'modify-email':
          return 'CP_UPDATE_CURRENT_EMAIL';
        case 'enable-auth-2fa':
          return 'CP_ENABLE_TOTP';
        case 'modify-auth-2fa':
          return 'CP_CHANGE_TOTP';
        case 'withdraw':
          return 'CP_WITHDRAW';
        case 'login-email-close':
          return 'CP_LOGIN_EMAIL_SWITCH_CLOSE';
        case 'login-totp-close':
          return 'CP_LOGIN_TOTP_SWITCH_CLOSE';
        default:
          return 'CP_LOGIN';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';

.contact_method {
  color: $blue;
  text-align: center;
  padding-top: 30px;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
}

/deep/ ul {
  @include rtl-sass-prop(padding-left, padding-right, 20px);

  li {
    font-size: 10px;
    line-height: 1.5;
    color: rgb(98, 109, 113);
    list-style: disc !important;
    font-size: 14px;
    font-weight: 400;
  }
}

.main_verification {
  margin-top: 0vh !important;

  text-align: start;
}
.title {
  padding: 0px;
  padding-bottom: 20px;
}

.body {
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  padding-bottom: 20px;

  /deep/ .el-form-item__label {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0 0 12px;
  }

  /deep/ .el-input__inner {
    height: 40px;
    padding-right: 55px;

    &:focus {
      border: 1px solid $blue;
    }
  }

  .send_code {
    width: auto;
    text-align: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .code_node {
    margin-top: 8px;
  }

  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .is-error {
  .el-form-item__content {
    .code_node {
      display: none;
    }
  }
}

.footer {
  padding-top: 16px;
  .footer_body {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /deep/ .el-button {
      border-radius: 8px;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      padding: 11px;
    }
  }
}
.word_break {
  word-break: normal;
}
</style>
