<template>
  <el-form :model="form" ref="form" :rules="rules">
    <div class="form-list">
      <el-form-item :label="$t('common.field.enterVerificationCode')" prop="code">
        <el-input :class="verifyError ? 'red_box' : ''" v-model="form.code" autocomplete="off">
          <div slot="suffix" v-if="showComponent?.showCodeButton">
            <span @click="sendEmail" v-if="!isSendEmail" class="fw-400 f-14 send_code blue_text">{{
              $t('common.button.sendCode')
            }}</span>
            <button v-else :disabled="true" class="fw-400 f-14 send_code lightgray_text">
              {{ `${$t('security.resend')} (${displayTime})` }}
            </button>
          </div>
        </el-input>
        <p v-if="verifyError" class="code_node red_text fw-400 f-12">{{ verifyError }}</p>
        <p v-else-if="!isSendEmail" class="code_node gray_text fw-400 f-14">{{ showComponent?.preDesc }}</p>
        <p v-else class="code_node gray_text fw-400 f-14">
          {{ showComponent?.postDesc ? showComponent?.postDesc : showComponent?.preDesc }}
        </p>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { apiSendEmailVerificationCode } from '@/resource';
import { md5, rsa } from '@/util/encrypt';

export default {
  name: 'VerificationCode',
  props: {
    namespace: String,
    showComponent: Object,
    token: {
      type: String,
      default: null
    },
    verifyError: {
      type: String,
      default: null
    },
    needEmail: {
      type: Boolean,
      default: false
    },
    sendEmailProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        code: ''
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t('common.formValidation.verCodeReq'),
            trigger: 'blur'
          }
        ]
      },
      isSendEmail: false,
      isClickSendEmail: false,
      secondTime: 60,
      displayTime: 0,
      timeInterval: null,
      errorCode: 0,
      txId: ''
    };
  },
  methods: {
    sendEmail() {
      const requestBody = {
        namespace: this.namespace,
        token: this.token
      };
      if (!this.validateEmail()) {
        return;
      }
      this.needEmail ? (requestBody['email'] = rsa(this.sendEmailProp)) : null;
      apiSendEmailVerificationCode(requestBody).then(resp => {
        this.isSendEmail = true;
        this.isClickSendEmail = true;
        this.startTimer();

        switch (resp.data.code) {
          case 0:
            this.txId = resp.data.data.txId;
            break;
          case 530:
            this.$router.push({ name: 'login' });
            break;
          default:
            this.errorCode = resp.data.code;
            break;
        }
      });
    },
    startTimer() {
      this.displayTime = this.secondTime;
      this.timeInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.displayTime--;
      if (this.displayTime === 0) this.stopTimer();
    },
    stopTimer() {
      clearInterval(this.timeInterval);
      this.isSendEmail = false;
    },
    getFormDataOfChild() {
      return {
        txId: this.txId,
        code: this.form.code
      };
    },
    submitForm() {
      return this.$refs['form'].validate();
    },
    validateEmail() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.needEmail) {
        if (this.sendEmailProp == '' || this.sendEmailProp == null || !pattern.test(this.sendEmailProp)) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';

.send_code {
  width: auto;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.code_node {
  margin-top: 8px;
}

/deep/.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-input__suffix-inner {
  height: 100%;
  display: inline-grid;
  margin-right: 10px;
}
</style>
